<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
        <CHeaderNavLink>
            <div class="c-avatar">
                <!-- <img src="img/avatars/6.jpg" class="c-avatar-img " /> -->
                <img :src=" user != null?  url+`images/users/${user.username}/${user.foto}` : `images/users/no_image.png`" alt="user" class="object-fit-cover">
            </div>
        </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>Settings</strong>
    </CDropdownHeader>
    <!-- <router-link to="/user-profile" class="waves-effect"> -->
    <CDropdownItem to="/user-profile">

        <CIcon name="cil-user" /> Profile

    </CDropdownItem>
    <!-- </router-link> -->

    <CDropdownItem @click="logout()">
        <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
</CDropdown>
</template>

<script>
import Swal from 'sweetalert2';
import iziToast from 'izitoast';
import store from '../store';

export default {
    name: 'TheHeaderDropdownAccnt',
    mounted() {
        // this.userDetail();

    },
    data() {
        return {
            itemCount: 42,
            loader: '',
            user: JSON.parse(localStorage.getItem('dataUser') || "[]"),
            detail: {},
            edit: {},
            kode_merchant: '',
        }
    },
    methods: {
        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        logout() {
            Swal.fire({
                title: 'Logout',
                text: "Yakin ingin logout ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#6777ef',
                cancelButtonColor: '#fc544b',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.Loader();
                    let uri = this.url + `api/v1/auth/logout`;
                    console.log(localStorage.getItem('token'));
                    
                    this.axios.post(uri, '',{
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token'),
                                // Authorization: 'Bearer ' + token,
                            }
                        })
                        .then((response) => {
                            if (response.data.success) {
                                // this.$auth.logout();
                                localStorage.removeItem('dataUser') //hapus data user yang tersimpan di local storage
                                store.commit('removeUser') //memanggil function untuk menghapus user
                                localStorage.removeItem('token') //menghapus token yang tersimpan
                                localStorage.removeItem('token_expire') //menghapus waktu expired
                                store.commit('logoutUser') //menghapus data user yang tersimpan di vuex
                                localStorage.removeItem('kode_merchant')
                                // window.location = '/auth/login';
                                setTimeout(() => {
                                    this.$router.push({
                                        name: 'login'
                                    });
                                    // const redirectTo = this.$auth.user().level === 'Admin' ? '/admin' : '/transaksi';
                                    this.loader.hide();

                                }, 3000);
                                iziToast.success({
                                    title: 'Berhasil',
                                    message: 'Logout berhasil!',
                                });
                            } else {
                                this.loader.hide();
                                iziToast.warning({
                                    title: 'Gagal!',
                                    message: 'Logout gagal!',
                                })
                            }

                        }).catch(error => {
                            console.log(error);
                            this.loader.hide();
                            iziToast.warning({
                                title: 'Gagal!',
                                message: 'Logout gagal!',
                            })
                        });

                }
            })

        }
    },
}
</script>

<style scoped>
.c-icon {
    margin-right: 0.3rem;
}

.object-fit-cover {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    /* margin-right: 10px; */
    object-fit: cover;
}
</style>
