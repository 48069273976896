<template>
<div class="c-app">
    <TheSidebar />
    <CWrapper>
        <TheHeader />
        <div class="c-body">
            <main class="c-main">
                <CContainer fluid>
                    <transition name="fade" mode="out-in">
                        <router-view :key="$route.path"></router-view>
                    </transition>
                </CContainer>
            </main>
        </div>
        <TheFooter />
    </CWrapper>
</div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import iziToast from 'izitoast';
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    name: 'TheContainer',
    components: {
        TheSidebar,
        TheHeader,
        TheFooter
    },
    // created() {
    //     this.interval = setInterval(() => this.checkSession(), 300000);
    // },

    mounted() {
        this.Loader();
        setTimeout(() => this.loader.hide(), 3 * 1000);
        if (!this.$store.state.isLoggedIn) {
            // this.$router.push({
            //     name: "login"
            // });
            this.$router.push("/login").catch(() => {});
            // window.location = 'auth/login';
        }else{
          this.$router.push("/").catch(() => {});
        }
        this.userDetail();

    },
    data() {
        return {
            itemCount: 42,
            loader: '',
            user: JSON.parse(localStorage.getItem('dataUser') || "[]"),
            detail: {},
            edit: {},
            kode_merchant: '',
            date: new Date,
            token_expire: localStorage.getItem("token_expire")
        }
    },
    methods: {
        Loader() {
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                loader: 'dots',
                color: '#6777ef'

            });
        },
        // checkSession() {
        //     console.log(this.token_expire);

        //     console.log("Token expired?", moment(this.token_expire).isBefore(Date.now()));
        //     if (moment(this.token_expire).isBefore(Date.now())) {

        //         console.log("token expired!");
        //         this.sessionEnd();
        //     }
        // },

        // sessionEnd() {
        //     Swal.fire({
        //         title: 'Sesi Telah Berakhir',
        //         text: "Silakan login kembali !",
        //         icon: 'warning',
        //         confirmButtonColor: '#6777ef',
        //         confirmButtonText: 'Ok',
        //         allowOutsideClick: false
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             // this.$auth.logout();
        //             localStorage.removeItem('dataUser') //hapus data user yang tersimpan di local storage
        //             store.commit('removeUser') //memanggil function untuk menghapus user
        //             localStorage.removeItem('token') //menghapus token yang tersimpan
        //             localStorage.removeItem('token_expire') //menghapus waktu expired
        //             store.commit('logoutUser') //menghapus data user yang tersimpan di vuex
        //             // window.location = '/auth/login';
        //             setTimeout(() => {
        //                 this.$router.push({
        //                     path: '/login'
        //                 });
        //                 // const redirectTo = this.$auth.user().level === 'Admin' ? '/admin' : '/transaksi';
        //             }, 3000);
        //         }
        //     })
        // },
        userDetail() {
            axios.get(this.url + `api/v1/user-profile/${this.user.kode_user}`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },

                }, )
                .then(res => {
                    this.detail = res.data.data;
                    console.log('/images/users/' + this.detail.username + '/' + this.detail.foto);
                    console.log(this.url + `images/users/${this.detail.username}/${this.detail.foto}`);
                    if (res.data.data.merchant != null) {
                        this.kode_merchant = localStorage.setItem('kode_merchant', res.data.data.merchant.kode_merchant);
                    }
                    if (this.detail.telegram_id == null || this.detail.telegram_id == "") {
                        Swal.fire({
                            title: 'Masukan ID Telegram',
                            html: 'ID Telegram bisa didapatkan melalui <a href="https://t.me/undikshafood_bot">Link ini</a>',
                            input: 'text',
                            showCancelButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'OK',
                            showLoaderOnConfirm: true,
                            preConfirm: (telegram_id) => {
                                this.edit.telegram_id = telegram_id;
                            },
                            // allowOutsideClick: () => !Swal.isLoading()
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.updateIDTelegram(this.detail.kode_user)
                            }
                        })
                    }

                });
        },
        updateIDTelegram(kode_user) {
            let formData = new FormData();
            formData.append('kode_user', kode_user);
            formData.append('telegram_id', this.edit.telegram_id);

            let uri = this.url + `api/v1/user-profile/updateTelegram`;
            this.Loader();
            // this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token');
            this.axios.post(uri, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    console.log(response);
                    this.loader.hide();
                    iziToast.success({
                        title: 'Berhasil',
                        message: 'Data berhasil disimpan!',
                    });
                    this.userDetail();
                }).catch(error => {
                    iziToast.warning({
                        title: 'Gagal!',
                        message: 'Data gagal disimpan!',
                    })
                    console.log(error);
                    this.loader.hide();
                    this.validation = error.response.data.data;
                });
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
