export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["MENU"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Kategori Layanan",
        to: "/admin/kategori-layanan",
        icon: "cil-user",
      },
      {
        _name: "CSidebarNavItem",
        name: "Daftar Layanan",
        to: "/admin/daftar-layanan",
        icon: "cil-user",
      },
    ],
  },
];